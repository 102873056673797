<template>
  <div
    class="auth-layout-wrap dank"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h1>Veel dank voor jouw aanvraag. Wij komen zo snel mogelijk bij je terug.  </h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

export default {
  metaInfo: {
    title: "Inloggen"
  },
  data() {
    return {
      email: "",
      password: "",
      userId: "",
      bgImage: require("@/assets/images/background-login.jpg"),
      logo: require("@/assets/images/logo-inverse.png")
    };
  },
  computed: {
    
  },

  methods: {
  }
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}

.dank {
  border-radius: 15px;
  margin-top: 2em;
}
</style>
